import { calculateBreakpoints } from 'platform/utils'

// Patterns from https://ipsec.pl/data-protection/2012/european-personal-data-regexp-patterns.html
const socialSecurityPatterns = {
  sv: [
    { name: 'sweden', regex: /\b((19|20)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[-+]?\d{4})\b/g },
  ],
  en: [
    { name: 'uk-ni', regex: /[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]?/g },
    {
      name: 'uk-nino',
      regex:
        /^([ACEHJLMOPRSW-Yacehjlmoprsw-y][A-CEGHJ-NPRSTW-Za-ceghj-nprstw-z]|[Bb][A-CEHJ-NPRSTW-Za-cehj-nprstw-z]|[Gg][ACEGHJ-NPRSTW-Zaceghj-nprstw-z]|[Kk][A-CEGHJ-MPRSTW-Za-ceghj-mprstw-z]|[Nn][A-CEGHJLMNPRSW-Za-ceghjlmnprsw-z]|[Tt][A-CEGHJ-MPRSTW-Za-ceghj-mprstw-z]|[Zz][A-CEGHJ-NPRSTW-Ya-ceghj-nprstw-y])[0-9]{6}[A-Da-d ]?$/g,
    },
    { name: 'uk-nhs', regex: /0-9]{3}[ -]?[0-9]{3}[-]?[0-9]{4}/g },
  ],
  nb: [{ name: 'norway', regex: /[0-9]{2}[0,1][0-9][0-9]{2}[ ]?[0-9]{5}/g }],
  de: [
    { name: 'germany', regex: /[0-9]{2}[0,1][0-9][0-9]{2}-[A-Z]-[0-9]{5}/g },
    { name: 'austria', regex: /[A-Za-z0-9+/]{22}[A-Za-z0-9+/=][A-Za-z0-9+/=]/g },
  ],
  da: [{ name: 'denmark', regex: /[0-9]{2}[0,1][0-9][0-9]{2}-[0-9]{4}/g }],
  fi: [{ name: 'finland', regex: /\d{6}[-Aa]\d{2}/g }],
  pl: [{ name: 'poland', regex: /[0-9]{4}[0-3]{1}[0-9}{1}[0-9]{5}/g }],
  es: [{ name: 'spain', regex: /[0-9,X,M,L,K,Y][0-9]{7}[A-Z]/g }],
  nl: [{ name: 'netherlands', regex: /[0-9]{9}/g }],
}

const defaultSensitivePatterns = [{ name: 'email', regex: /\b([\w-.]+@([\w-]+\.)+[\w-]{2,4})/g }]

let warningVisible = false

let usedSensitiveStrings = ''

export function initSensitiveTextWarnings() {
  if (!$('body').data('is-editor-for-page')) {
    const elm = $('[data-fn-sensitive-text-check]')
    if (!elm.length) {
      return
    }
    elm.on('keyup paste', checkForSensitiveText)

    const sendPrivately = $<HTMLInputElement>('[data-fn-toggle-private-message]')
    sendPrivately.on('click', (e) => {
      if (e.target.checked) {
        hideHint()
      }
    })
  }
}

function checkForSensitiveText() {
  const elm = $(this)
  let text = (elm.val() as string).trim()
  if (!text) {
    text = KUNDO.formatting.getTextEditorHTML(elm.find('textarea'))
  }
  const matches = getMatches(text)

  if (matches) {
    if (matches !== usedSensitiveStrings) {
      usedSensitiveStrings = matches
      showHint(elm)
    }
  } else {
    usedSensitiveStrings = ''
  }
  if (!matches && warningVisible) {
    hideHint()
  }
}

function showHint(elm) {
  let left = 0
  let top = 0

  let hint = getWarningBox() as any
  elm.before(hint)

  let hintTopOffset = hint.height() + 50
  const hintLeftOffset = 80
  let caretPositionTitle

  try {
    const caretPosition = elm.textareaHelper('caretPos')
    const isMobile = calculateBreakpoints().mobile
    top = caretPosition.top - hint.height() - 30 - 7
    left = isMobile ? 0 : caretPosition.left - (hint.width() + 30 - 14) / 8
  } catch (error) {
    const caretPosition = KUNDO.formatting.getTextEditorCaretPosition(elm)
    if (caretPosition.length <= 0) {
      caretPositionTitle = elm[0].getBoundingClientRect()
    }
    const parentRect = elm.parent()[0].getBoundingClientRect()

    if (caretPositionTitle) {
      hintTopOffset -= 15
      top = caretPositionTitle.top - parentRect.top - hintTopOffset
      left = caretPositionTitle.left - parentRect.left
    } else {
      top = caretPosition.top - parentRect.top - hintTopOffset
      left = caretPosition.left - parentRect.left - hintLeftOffset
    }
  }

  hint.css({
    top: `${top}px`,
    left: `${left}px`,
  })

  hint.show()

  // adjust if placed outside window
  if (hint.offset().left <= 0) {
    hint.css({
      left: '-40px',
    })
  }
  warningVisible = true
}

function getSensitivePatterns(): any[] {
  if (!window.SENSITIVE_TEXT_CONFIG) {
    return []
  }
  const { sensitiveTextWarningLanguage } = window.SENSITIVE_TEXT_CONFIG
  // @ts-expect-error
  const customSensitivePatterns = window.customSensitivePatterns || []
  const patterns = []
    // @ts-expect-error
    .concat(defaultSensitivePatterns)
    .concat(socialSecurityPatterns[sensitiveTextWarningLanguage] || [])
    .concat(customSensitivePatterns)
  return patterns
}

export function getMatches(text) {
  let matches = ''
  let sensitivePatterns = getSensitivePatterns()

  for (let i = 0, len = sensitivePatterns.length; i < len; i++) {
    let pattern = sensitivePatterns[i]
    let result = pattern.regex.exec(text)
    while (result) {
      matches += result[0]
      if (!pattern.regex.global) {
        break
      }
      result = pattern.regex.exec(text)
    }
  }
  return matches
}

// Use a global instance to make sure
// there is only one aria alert region
let warningBox: JQuery | null

function getWarningBox() {
  if (warningBox) {
    return warningBox
  }

  warningBox = $(`<div class='hint-warning'>`)
  if (window.SENSITIVE_TEXT_CONFIG?.disallowPostWithSensitiveText) {
    return warningBox.append($('<div role="alert">').text(TRANSLATIONS.sensitive_text_blocker))
  }

  return warningBox.append(
    $('<div role="alert">').text(TRANSLATIONS.sensitive_text_warning),
    // Add buttons to allow dismissing warning as posting is still allowed
    $(`<button type="button" class="close-x" aria-hidden="true">`).on('click', hideHint).text('x'),
    $(`<button type="button" class="close-text mt-4">`)
      .on('click', hideHint)
      .text(TRANSLATIONS.sensitive_text_confirmation),
  )
}

function hideHint() {
  if (warningVisible) {
    warningVisible = false
  }
  warningBox?.fadeOut(400, () => {
    warningBox?.hide()
  })
}
