/* eslint-disable no-console */

export function getOrgSlug({ pathname }): null | string {
  const matches = pathname.match(/(org|landingpage|facebook)\/([^?/]+)/)
  if (matches) {
    return matches[2] || null
  }
  return null
}

/*
 * This event handler is used in SPA-iframe in order to resize the iframe when dropdown is toggled.
 */
const contentIframe = document.querySelector('#main-content-iframe') as HTMLIFrameElement

window.document.addEventListener(
  'iframe-content',
  async (e) => {
    const detail = await (e as CustomEvent).detail
    contentIframe.style.height = `${detail.height}px`
  },
  false,
)

window.addEventListener('load', function () {
  const sendEmailButton = parent.document.getElementById('send-email-from-spa')
  const callsButton = parent.document.getElementById('calls-from-spa')

  // Update parent header title, which is Storkundo
  this.parent.parent.document.title = document.title

  if (sendEmailButton) {
    KUNDO.overlay.attachHTMLElement(sendEmailButton)
  }

  if (callsButton) {
    var openCallsInstanceEvent = new CustomEvent('open-calls-instance', {
      detail: { callsButton },
    })
    // attach click handler
    window.document.dispatchEvent(openCallsInstanceEvent)
  }
})
