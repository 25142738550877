export function initHighlightComment() {
  const url = window.location.hash
  const regex = new RegExp(`^\\#c[0-9]+$`, 'i')

  // Highlights the selected comment, or
  // shows a popup explaining that it was removed.
  if (url.match(regex)) {
    const elm = $(url)
    if ($(url).length) {
      flash(elm)
    } else {
      KUNDO.overlay.attach($('.fn-missing-comment'))
    }
  }
}

function flash(elm: JQuery) {
  elm.append('<div class="fade-out"></div>')
  $('.fade-out').fadeOut(1200)

  setTimeout(() => $('.fade-out').remove(), 1250)
}
