export default function overflowedSupportLinks() {
  // prettier-ignore
  const button = document.querySelector<HTMLButtonElement>('.fn-toplist__more-support-links-toggler')
  if (!button || !button.parentNode) {
    return
  }

  const supportLinks = [].slice.call(button.parentNode.querySelectorAll('a'))
  const overflowSupportLinksMenu = document.getElementById(button.getAttribute('aria-controls')!)!
  const topOffset = 10
  let escapeListener
  let outsideClickListener

  const showOverflowSupportLinksMenu = () => {
    overflowSupportLinksMenu.innerHTML = ''
    supportLinks.forEach((link) => {
      if (link.offsetTop > topOffset) {
        const clone = link.cloneNode(true)
        clone.removeAttribute('aria-hidden')
        clone.removeAttribute('tabindex')
        overflowSupportLinksMenu.appendChild(clone)
      }
    })
    button.setAttribute('aria-expanded', 'true')
    overflowSupportLinksMenu.removeAttribute('hidden')
    document.addEventListener('keydown', escapeListener)
    document.addEventListener('click', outsideClickListener)
  }

  const hideOverflowSupportLinksMenu = () => {
    button.setAttribute('aria-expanded', 'false')
    overflowSupportLinksMenu.setAttribute('hidden', '')
    document.removeEventListener('keydown', escapeListener)
    document.removeEventListener('click', outsideClickListener)
  }

  const toggleHiddenSupportLinks = (event) => {
    event.stopPropagation()

    if (overflowSupportLinksMenu.hasAttribute('hidden')) {
      showOverflowSupportLinksMenu()
    } else {
      hideOverflowSupportLinksMenu()
    }
  }

  const supportLinksAttributeUpdater = () => {
    let allSupportLinksVisible = true

    supportLinks.forEach((link) => {
      if (link.offsetTop > topOffset) {
        link.setAttribute('aria-hidden', 'true')
        link.setAttribute('tabindex', '-1')
        allSupportLinksVisible = false
      } else {
        link.removeAttribute('aria-hidden')
        link.removeAttribute('tabindex')
      }
    })

    button.removeEventListener('click', toggleHiddenSupportLinks)

    if (allSupportLinksVisible) {
      button.setAttribute('aria-hidden', 'true')
      button.setAttribute('tabindex', '-1')
      button.setAttribute('hidden', '')
    } else {
      button.removeAttribute('aria-hidden')
      button.removeAttribute('tabindex')
      button.removeAttribute('hidden')
      button.addEventListener('click', toggleHiddenSupportLinks)
    }
  }

  let resizeTimer

  escapeListener = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      if (overflowSupportLinksMenu.contains(document.activeElement)) {
        button.focus()
      }
      hideOverflowSupportLinksMenu()
    }
  }

  outsideClickListener = (event) => {
    if (!overflowSupportLinksMenu.contains(event.overflowSupportLinksMenu)) {
      hideOverflowSupportLinksMenu()
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(() => {
      supportLinksAttributeUpdater()
    }, 250)
  })

  supportLinksAttributeUpdater()
}
