import './cookies'
import './scroll-to'

import { Autocomplete } from './autocomplete'
import { ForumDialogForm } from './forum-dialog-form'
import { initHighlightComment } from './highlight'
import overflowedSupportLinks from './overflowed-support-links'
import { initSensitiveTextWarnings } from './sensitive-text-warning'

KUNDO.dependencies.addOnce([
  // fmt: expand
  Autocomplete,
  ForumDialogForm,
  initHighlightComment,
  initSensitiveTextWarnings,
  overflowedSupportLinks,
])

KUNDO.dependencies.add([
  // fmt: expand
  KUNDO.scroll_to,
])
