export function forEachElement(selector: string, callback: (elm: HTMLElement) => void) {
  // IE doesn't support document.querySelectorAll(...).forEach, but this helper does
  Array.prototype.forEach.call(document.querySelectorAll(selector), callback)
}

class PermanentCloseLink {
  elm: JQuery<HTMLElement>
  url: string

  constructor(elm: JQuery<HTMLElement>) {
    this.elm = elm
    this.url = this.elm.attr('href') as string
    this.elm.click(this.doAjaxCall)
  }

  doAjaxCall() {
    $.post($(this).attr('href'))
    $(this).closest('.fn-permanent-close-container').hide()
    return false
  }
}

export const Utils = (() => ({
  PermanentCloseLink,

  getFunctionByName(name?: string) {
    // note! function name must be in format "KUNDO.example.foo"
    if (!name) {
      return null
    }

    const parts = name.split('.')
    return window[parts[0]][parts[1]][parts[2]]
  },

  validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  },

  isUrl(url: string) {
    // Based on regexp from http://stackoverflow.com/a/14582229
    const pattern = new RegExp(
      '^(https?:\\/\\/)' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i',
    )

    return pattern.test(url)
  },
}))()

KUNDO.utils = Utils

export type Breakpoint = {
  desktop: boolean
  mobile: boolean
  tablet: boolean
}

export function calculateBreakpoints(): Breakpoint {
  return {
    desktop: window.innerWidth > 1024,
    mobile: window.innerWidth < 600,
    tablet: window.innerWidth > 600 && window.innerWidth < 1024,
  }
}

export function waitForTime(timeout?: number): Promise<void> {
  return new Promise((resolve) => {
    if (timeout === undefined) {
      window.requestAnimationFrame(() => resolve())
    } else {
      setTimeout(() => resolve(), timeout)
    }
  })
}

export async function retryFunction(fn: () => any, retries: number = 5, interval: number = 20) {
  try {
    await fn()
  } catch (exception) {
    if (retries <= 0) {
      return Promise.reject()
    }

    await waitForTime(interval)

    return retryFunction(fn, retries - 1, interval)
  }
}
