import { calculateBreakpoints } from 'platform/utils'

export const FORUM_DATA_KEY = 'forumDialogFormData'

export const isCreateUrl = (path) => /\/create(\/?)$/.test(path)
const isMobile = calculateBreakpoints().mobile
const doScroll = (target) => {
  const offset = $(target).offset()
  if (offset) {
    $('html, body').animate({ scrollTop: offset.top }, 300)
  }
}

export const toggleCreatePath = (path) => {
  const parts = path.split('/').filter((part) => part.length)
  if (isCreateUrl(path)) {
    parts.pop()
  } else {
    parts.push('create')
  }
  return `/${parts.join('/')}/`
}

const updateNavigationState = () => {
  const location = toggleCreatePath(window.location.pathname)
  window.history.replaceState({ forum: 'dialog' }, '', location)
}

export const minutesDiff = (timestamp) =>
  // @ts-expect-error
  Math.round((new Date() - new Date(Number.parseInt(timestamp, 10))) / 1000 / 60)

export const ForumDialogForm = {
  init() {
    $('.fn-toggle-dialog-form').on('click', this.toggleDialogForm)
    $('[data-fn-toggle-private-message]').on('click', this.toggleHelpText)
    $('.fn-set-search-mode').on('focus', this.setSearchMode)
    $('#id_archived').on('change', this.resetCommentButtonText)

    const dialogForm = $('.common-form-dialog')
    if (dialogForm.length) {
      this.populateFromLocalStorage = this.populateFromLocalStorage.bind(this)
      this.resetLocalStorage = this.resetLocalStorage.bind(this)
      dialogForm.on('submit', this.resetLocalStorage)
      $('.common-form-dialog input[name="title"]').on('blur', this.handleInputBlur)
      $('.common-form-dialog textarea[name="text"]').on('froalaEditor.blur', this.handleInputBlur)
      this.populateFromLocalStorage()
    }

    document.addEventListener(
      'kundo--reset-forum-button-text',
      ForumDialogForm.resetCommentButtonText,
    )
  },

  toggleDialogForm() {
    const form = $('.common-form-dialog')
    const elm = $(this)
    form.toggleClass('hidden').toggleClass('expanded').toggleAttr('aria-hidden', 'true', 'false')

    elm.toggleAttr('aria-expanded', 'true', 'false')
    if (elm.attr('aria-expanded')) {
      form.find('input[type=text]').first().focus()
    }

    updateNavigationState()

    $('#fn-search-box-autocomplete-result').hide()

    if (isMobile && !form.hasClass('hidden')) {
      doScroll(form)
    }

    return false
  },

  toggleHelpText() {
    $('[data-fn-toggle-text]').toggleClass('hidden').toggleAttr('aria-hidden', 'true', 'false')
    $('[data-fn-toggle-border]').toggleClass('form__border-top')
  },

  handleInputBlur() {
    const title = $('.common-form-dialog input[name="title"]').val()
    const text = KUNDO.formatting.getTextEditorHTML($('.common-form-dialog textarea[name="text"]'))
    localStorage.setItem(
      FORUM_DATA_KEY,
      JSON.stringify({
        createdAt: new Date().getTime(),
        text: text,
        title: title,
      }),
    )
  },

  populateFromLocalStorage() {
    // @ts-expect-error
    const storedData = JSON.parse(localStorage.getItem(FORUM_DATA_KEY))
    const halfAnHour = 30

    if (!storedData || minutesDiff(storedData.createdAt) > halfAnHour) {
      // Don´t populate old entries or if there is no data is stored
      return
    }

    const { text, title } = storedData
    const form = $('.common-form-dialog')

    form.find('input[type=text]').first().val(title)
    KUNDO.formatting.setTextEditorHTML(form.find('textarea').first(), text)
  },

  resetLocalStorage() {
    localStorage.removeItem(FORUM_DATA_KEY)
  },

  setSearchMode() {
    // Hide dialog form
    const form = $('.common-form-dialog')

    if (!form.hasClass('hidden')) {
      updateNavigationState()
    }

    form.addClass('hidden').attr('aria-hidden', 'true').removeClass('expanded')
    $('.fn-toggle-dialog-form').attr('aria-expanded', 'false')

    // Show search results if it exists
    if ($(this).data('has-search-result')) {
      $('#fn-search-box-autocomplete-result').show()
    }

    return false
  },

  resetCommentButtonText() {
    const button = $('#comment-form').find('.fn-comment-btn')
    let needsModerationCount

    // moderation.es6.js makes a call here with a number but we also want to use this without parameters
    if (Number(arguments[0]) || Number(arguments[0]) === 0) {
      needsModerationCount = Number(arguments[0])
    } else {
      needsModerationCount = document.querySelectorAll('.needs-moderation').length
    }

    const isArchived = $('#id_archived').is(':checked')
    let text = button.text()

    // Note: These rules are also implemented server side, in the comment form. Any changes should be synced
    if (isArchived || needsModerationCount === 0) {
      text = TRANSLATIONS.no_moderation_btn_text
    } else if (needsModerationCount === 1) {
      text = TRANSLATIONS.single_moderation_btn_text
    } else if (needsModerationCount > 1) {
      text = TRANSLATIONS.multiple_moderation_btn_text
    }
    button.text(text)
  },
}
