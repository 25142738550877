import { calculateBreakpoints } from 'platform/utils'

import { getOrgSlug } from '../utils'

const animationTime = 0
const searchMinLength = 3
const searchMaxLength = 500
const searchMaxResults = 3

function stripHtml(text: string): string {
  // NOTE: Does not take "implicit" spaces between elements into account, so
  // >>> stripHtml("foo<br>bar") === "foobar"
  return $('<div>').html(text)[0].textContent || ''
}

const isMobile = calculateBreakpoints().mobile

const cache = {}
let orgSlug: null | string = null
const isEmbed = $('body').hasClass('fn-embed') || $('body').hasClass('fn-facebook')
const isFacebook = $('body').hasClass('fn-facebook')

export const Autocomplete = {
  init() {
    const elements = $('.fn-autocomplete')
    if (!elements.length) {
      return
    }
    orgSlug = getOrgSlug(window.location)
    if (!orgSlug) {
      return
    }

    const searchField = $('.fn-search-box-field')
    const titleField = $('.fn-title')
    const textEditorElm = $('.fn-text-editor textarea')
    const isMinimal = searchField.hasClass('fn-autocomplete--minimal')

    if (searchField) {
      Autocomplete.setUpAutoComplete(searchField, isMinimal)
    }
    if (titleField) {
      Autocomplete.setUpAutoComplete(titleField)
    }
    if (textEditorElm && titleField) {
      textEditorElm.on('froalaEditor.contentChanged', () => Autocomplete.doAutocomplete(titleField))
      Autocomplete.doAutocomplete(titleField)
    }
    Autocomplete.setupFocusHandler(searchField)
  },

  getCurrentSearchTerm(elm) {
    // Combine search terms from multiple fields if applicable and remove placeholder
    // text from searchterm (because we use jQuery.placeholder for IE)
    if (elm.data('combined-search')) {
      const textEditorElm = $('.fn-text-editor textarea')
      const titleTerm = getTextWithoutPlaceholder($('#id_title'))
      const textTerm = stripHtml(KUNDO.formatting.getTextEditorHTML(textEditorElm))
      return `${titleTerm.trim()} ${textTerm.trim()}`
    } else {
      return getTextWithoutPlaceholder(elm)
    }
  },

  showAutocompleteResult(data, totalHits, maxResults, term, containerId, callback) {
    let items = ''
    const target = isEmbed ? '_blank' : ''
    const result = $(`#${containerId}`)
    if (result.is(':visible') && !totalHits) {
      Autocomplete.hideAutocompleteResult(result)
      result.find('h3').html(`<span>${window.TRANSLATIONS.no_hits}</span>`)
      result.find('.content').hide()
    }

    $.each(data, (i: number, hit) => {
      if (i < maxResults) {
        items += `<li><a href="${hit.absolute_url}" target="${target}" class="autocomplete-result-link">
            <span class="title">${hit.title}</span></a>
            <p class="search_desc">${hit.text}</p></li>`
      }
    })

    if (totalHits > maxResults) {
      result.find('.fn-autocomplete-more').show()
    } else {
      result.find('.fn-autocomplete-more').hide()
    }

    if (data.length > 0) {
      result.find('ul').html(items)
      result.find('h3').html(`<span>${window.TRANSLATIONS.similar_posts}</span>`)
      result
        .find('.fn-autocomplete-more')
        .attr('href', `/org/${orgSlug}/search/?q=${term}`)
        .attr('target', target)
      result.find('.content').show()
      result.fadeIn(animationTime)
    }

    callback.call(this, data.length > 0)
  },

  hideAutocompleteResult(elm) {
    elm.hide()
  },

  doAutocomplete(elm: JQuery<HTMLElement>, request?, response?) {
    const term = Autocomplete.getCurrentSearchTerm(elm).trim()
    const containerId = elm.attr('data-result-container')
    if (term.length < searchMinLength) {
      const result = $(`#${containerId}`)
      Autocomplete.hideAutocompleteResult(result)
    }
    if (term.length > searchMaxLength) {
      return
    }
    const minLength = toInt(elm.attr('data-search-min-length'), searchMinLength)
    const maxResults = toInt(elm.attr('data-max-results'), searchMaxResults)
    if (term.length < minLength) {
      elm.removeData('has-search-result')
      return
    }
    const callback = function (hasResults) {
      if (hasResults) {
        elm.data('has-search-result', 'true')
      } else {
        elm.removeData('has-search-result')
      }
    }

    const searchTerm = encodeURIComponent(term)
    const cachedResult = cache[searchTerm]
    if (cachedResult) {
      const { data, totalHits } = cachedResult
      Autocomplete.showAutocompleteResult(
        data,
        totalHits,
        maxResults,
        searchTerm,
        containerId,
        callback,
      )
      return
    }
    const url = `/org/${orgSlug}/json-search/${searchTerm}.json?limit=5&livesearch=1`
    $.ajax({
      dataType: 'json',
      success(data, textStatus, xhr) {
        const totalHits = xhr.getResponseHeader('X-TotalResults')
        cache[searchTerm] = { data, totalHits }
        Autocomplete.showAutocompleteResult(
          data,
          totalHits,
          maxResults,
          searchTerm,
          containerId,
          callback,
        )
        // @ts-ignore
        if (window.gtag && window.KUNDO_GA_CODE) {
          // @ts-ignore
          window.gtag('config', window.KUNDO_GA_CODE, {
            page_location: window.location.origin + url,
          })
        }
      },
      url,
    })
  },

  turn_off() {
    $('.fn-autocomplete').off()
  },

  setUpAutoComplete(elm, isMinimal = false) {
    const element = $(elm)
    if (!(element.hasClass('fn-textarea') && (isMobile || isFacebook))) {
      element.autocomplete({
        delay: 400,
        minLength: 0,
        source(request, response) {
          Autocomplete.doAutocomplete(element, request, response)
        },
      })
      if (!isMinimal) {
        // Trigger search if fields are already prefilled
        element.autocomplete('search')
      }
    }
  },

  setupFocusHandler(searchField: JQuery<HTMLElement>) {
    const resultContainerId = searchField.attr('data-result-container')
    const resultContainer = $(`#${resultContainerId}`)
    $(document).on('click', (event) => {
      if (!resultContainer.is(':visible')) {
        return
      }
      const { target } = event
      if (!(resultContainer[0].contains(target) || searchField[0].contains(target))) {
        resultContainer.hide()
      }
    })
  },
}

function toInt(text: undefined | null | string, fallback: number) {
  if (text) {
    return Number.parseInt(text)
  }
  return fallback
}

function getTextWithoutPlaceholder(elm) {
  let value = elm.val()
  if (!value) {
    value = ''
  }
  if (elm.attr('placeholder')) {
    // remove placeholder text from search terms (because we use jQuery.placeholder for IE)
    value = value.replace(elm.attr('placeholder'), '')
  }
  value = value.replace(/[\t\n\r]+/g, ' ')
  value = value.trim()
  return value
}
