export {}

KUNDO.scroll_to = (function () {
  let isInitialized = false

  return {
    scrollToAnchor() {
      const target = $(this.hash)
      KUNDO.scroll_to.doScroll(target)
    },

    doScroll(target) {
      const offset = $(target).offset()
      if (offset) {
        $('html, body').animate({ scrollTop: offset.top }, 300)
      }
    },

    doJump(target) {
      const offset = $(target).offset()
      if (offset) {
        $('html, body').scrollTop(offset.top)
      }
    },

    init(isMobile) {
      if (!isInitialized) {
        isInitialized = true
        $('.fn-scroll-to').click(KUNDO.scroll_to.scrollToAnchor)
      }
    },
  }
})()
